<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">{{title}} - ตั้งค่า</h3>
      </div>
    </div>

    <hr class="my-3">

    <div class="">
      <div class="form-row">
        <sgv-input-select
          class="col-12"
          label="ประเภท"
          :options="options"
          select="id"
          v-model="selected">
          <template slot-scope="option">
            {{option.id}} ({{option.description}})
          </template>
        </sgv-input-select>
      </div>

      <DocConfigListReport
        v-if="['reportGroup', 'reportSales'].includes(selected)"
        :docType="docType"
        :templateType="templateType"
        :reportType="selected">
      </DocConfigListReport>

      <DocConfigList
        v-else-if="selected && menus[selected]"
        :docType="docType"
        :templateType="templateType"
        :selected="selected"
        :menu="menus[selected]">
      </DocConfigList>
    </div>
  </div>
</template>

<script>
import DocConfigList from './DocConfigList.vue'
import DocConfigListReport from '@/views/doc_core/components/DocConfigListReport.vue'
import { LIST_DOC_CONFIG_MENU } from './graph'


export default {
  metaInfo () {
    return {
      title: `${this.title}-ตั้งค่า`
    }
  },
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      docConfigs: [],
      items: [],
      menus: {},
      selected: 'userManual'
    }
  },
  apollo: {
    menus: {
      query () {
        return LIST_DOC_CONFIG_MENU(this.templateType)
      },
      variables () {
        return {docType: this.docType}
      },
      fetchPolicy: 'network-only'
    }
  },
  computed: {
    options () {
      return Object.keys(this.menus).map(v => {
        return {
          id: v,
          attributes: this.menus[v].attributes,
          description: this.menus[v].description,
          optionals: this.menus[v].optionals,
          inventoryTypes: this.menus[v].inventoryTypes || [],
          paperTypes: this.menus[v].paperTypes || [],
        }
      })
    },
  },
  components: {
    DocConfigList,
    DocConfigListReport
  }
}
</script>

<style lang="css">
</style>
